import React, { useState } from 'react'
import styles from '../../styles/Home.module.css'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import dynamic from 'next/dynamic'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Icon from '../Icon'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const Button = dynamic(() => import('@/components/Common/WhiteButton'))

const NewBanner = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className={styles.NewBannerBG}>
        <Container>
          <Row style={{ marginTop: 80 }}>
            <Col className={styles.bannerLeftCol} lg={6} md={12}>
              <h1 className={styles.BannerHeading}>
                Cloud Based All-in-One <br />
                Social HR Software
              </h1>
              <p className={  styles.BannerSubHeading}>
                WebHR automates all of your company&apos;s HR processes
                <br /> such as Recruitment, Onboarding, Payroll, Time & Attendance, <br /> Leaves & PTO, Performance, and much more... 
              </p>
              <div style={{ display: 'flex', flexDirection: 'row', marginBlock: 40 }}>
                <Button text={'Try for free'} purpleButton link={'/pricing'} />
                <Button text={'Watch Video'} image onClick={() => setModalShow(true)} />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/landing_page/banner_image.png" style={{width:"100%", height:"100%",}} className={styles.responsiveBannerImage} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className={styles.rankedSection}>
        <Container>
          <Row className={styles.rankedRow}>
            <Col md={5} style={{ fontSize: 17 }}>
              Ranked as Number #1
              <br /> Online HR Software Globally
            </Col>
            <Col className={styles.rankedRow}>
              <h5>28,000 +</h5>
              <p> Companies Onboard</p>
            </Col>
            <Col className={styles.rankedRow}>
              <h5>200 +</h5>
              <p> Countries Globally</p>
            </Col>
            <Col className={styles.rankedRow}>
              <h5>1 Million +</h5>
              <p> Employees Served</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={() => setModalShow(false)}>
            <Icon Icon={faClose} size={25} style={{}} />
          </div>
          <iframe src="https://player.vimeo.com/video/568588731" style={{ width: '100%', height: 600 }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default NewBanner
